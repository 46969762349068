@import "css/variables.scss";
@import "themes/custom.scss";
@import "css/utilities.scss";
@import "css/skeleton.scss";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: greenyellow;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.chefmance-logo-top {
  fill: #fff;
  vertical-align: baseline;
}

.link-primary {
  color: $primary-button-link-color;
  &:hover {
    color: $primary-button-link-hover-color;
  }
}

.recipe-section-with-bg {
  background-color: $recipe-title-bg-color;
}

.card-no-border {
  border: none;
  @media(min-width: 992px) {
    border: 1px solid rgba(0, 0, 0, 0.125);
  }
}
.recipe-details-title {
  font-size: $recipe-details-title-font-size;
}
.badge-light {
  background-color: $badge-light-bg;
  padding: 4px 15px 4px 15px;
  border-radius: 2px;
}

.recipe-card-img {
  @include image-center;
  margin-top: 20px;
  width: $recipe-card-img-width;
  height: $recipe-card-img-height;  
}

.recipe-full-img {
  @include image-center;
  width: $recipe-full-img-mobile-width;
  height: $recipe-full-img-mobile-height;
  @media(min-width: 992px) {
    width: $recipe-full-img-width;
    height: $recipe-full-img-height;
  }
}

.caret-icon {
  width: $caret-icon-mobile-width;
  height: $caret-icon-mobile-height;
  @media(min-width: 992px) {
    width: $caret-icon-width;
    height: $caret-icon-height;
    color: $caret-icon-desktop-color;
  }
}

.recipe-of-day-img {
  @include image-center;
  margin-top: 20px;
  width: $recipe-of-day-card-img-width-mobile;
  height: $recipe-of-day-card-img-height-mobile;
@media(min-width: 992px) {
    width: $recipe-of-day-card-img-width;
    height: $recipe-of-day-card-img-height;
  }
}

.top-recipe-small-img {
  object-fit: cover;
  @media(min-width: 992px) {
    width: $top-recipe-small-card-img-width;
    height: $top-recipe-small-card-img-height;
  }
}

.with-wait:empty {
  margin: auto;
  width: 350px;
  height: 600px; /* change height to see repeat-y behavior */
  
  background-image:
    radial-gradient( circle 50px at 50px 50px, lightgray 99%, transparent 0 ),
    linear-gradient( 100deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 80% ),
    linear-gradient( lightgray 20px, transparent 0 ),
    linear-gradient( lightgray 20px, transparent 0 ),
    linear-gradient( lightgray 20px, transparent 0 ),
    linear-gradient( lightgray 20px, transparent 0 );

  background-repeat: repeat-y;

  background-size:
    100px 200px, /* circle */
    50px 200px, /* highlight */
    150px 200px,
    350px 200px,
    300px 200px,
    250px 200px;

  @media(min-width: 992px) {
    background-size:
    100px 200px, /* circle */
    50px 200px, /* highlight */
    450px 200px,
    650px 200px,
    750px 200px,
    950px 200px;
  }
  
  background-position:
    0 0, /* circle */
    0 0, /* highlight */
    120px 0,
    120px 40px,
    120px 80px,
    120px 120px;

  animation: shine 1s infinite;
}

@keyframes shine {
  to {
    background-position:
      0 0,
      100% 0, /* move highlight to right */
      120px 0,
      120px 40px,
      120px 80px,
      120px 120px;
  }
}