/*
 * Variables
 */

:root {
  // these variables will be overwritten in mixin
  --title-position: 0px 1px;  
  --desc-line-1-position: 0 242px;
  --desc-line-2-position: 0 265px;  
  --footer-position: 0 200px;  
}

@mixin card-skeleton($card-width, $card-height) {
  &:empty::after {
  content:"";
  display: inline-block;
  width: $card-width;
  height: $card-height;
  border-radius:6px;
  box-shadow: 0 10px 45px rgba(0,0,0, .1);

  background-image:
    linear-gradient(
      90deg, 
      rgba(lightgrey, 0) 0, 
      rgba(lightgrey, .8) 50%, 
      rgba(lightgrey, 0) 100%
    ),                          //animation blur
    linear-gradient(lightgrey $card-height, transparent 0)        //card
  ;
  
  background-position:
    0 0                           //card
  ;

  background-repeat: no-repeat;

  // set to animate
  animation: loading 1.5s infinite;
  }
}
/*
 * Card Skeleton for Loading
 */

// .card-when-empty-old {  
//   &:empty::after {
//     content:"";
//     display:block;
//     width: var(--card-width);
//     height: var(--card-height);
//       border-radius:6px;
//     box-shadow: 0 10px 45px rgba(0,0,0, .1);

//     background-image:
//       linear-gradient(
//         90deg, 
//         rgba(lightgrey, 0) 0, 
//         rgba(lightgrey, .8) 50%, 
//         rgba(lightgrey, 0) 100%
//       ),                          //animation blur
//       var(--title-skeleton),      //title
//       var(--desc-line-skeleton),  //desc1
//       var(--desc-line-skeleton),  //desc2
//       var(--footer-skeleton),     //footer bar
//       var(--card-skeleton)        //card
//     ;

//     background-size:
//       var(--blur-size),
//       var(--card-width) var(--title-height),
//       var(--desc-line-1-width) var(--desc-line-height),
//       var(--desc-line-2-width) var(--desc-line-height),
//       100% var(--footer-height),
//       100% 100%
//     ;
    
//     background-position:
//       -150% 0,                      //animation
//       var(--title-position),        //title
//       var(--desc-line-1-position),  //desc1
//       var(--desc-line-2-position),  //desc2
//       var(--footer-position),       //footer bar
//       0 0                           //card
//     ;

//     background-repeat: no-repeat;
//     animation: loading 1.5s infinite;
//   }
// }



@keyframes loading {
  0%   { opacity: 1; }
  25% { opacity: 0.5; }
  50% { opacity: 0.25; }
  75% { opacity: 0.5; }
  100% { opacity: 1; }
}

.card-when-empty {
  @include card-skeleton(347px, 248px);
}

.top-recipe-when-empty {
  @include card-skeleton(337px, 124px);
}

.category-card-when-empty {
  @include card-skeleton(100%, 154px);
}