$primary-button-link-color: #fff;
$primary-button-link-hover-color: #ddd;
$recipe-title-bg-color: #d87373;
$recipe-details-title-font-size: 2.25rem;
$badge-light-bg: #c0c1c2;

// card image sizes
$recipe-card-img-width: 303px;
$recipe-card-img-height: 184px;

$recipe-full-img-width: 525px;
$recipe-full-img-height: 525px;

$recipe-full-img-mobile-width: 325px;
$recipe-full-img-mobile-height: 325px;

// mobile dimensions
$recipe-of-day-card-img-width-mobile: 283px;
$recipe-of-day-card-img-height-mobile: 164px;
// desktop
$recipe-of-day-card-img-width: 303px;
$recipe-of-day-card-img-height: 184px;

$top-recipe-small-card-img-width: 100px;
$top-recipe-small-card-img-height: 80px;

// caret icons / arrows
$caret-icon-mobile-width: 20px;
$caret-icon-mobile-height: 20px;
$caret-icon-width: 30px;
$caret-icon-height: 30px;

$caret-icon-desktop-color: #888;